import React from "react";
import {
    Card,
    CardContent,
    CardMedia,
    Tooltip,
    Typography,
    Button,
    Divider,
    Container,
    Link,
} from "@mui/material";
import HitCount from "./HitCount";
import moment from "moment";

export default function Play({ score }) {
    let s = moment(new Date(score.date)).fromNow();
    return (
        <Tooltip title={"#" + score.rankNo + " - " + s} placement="top">
            <Card sx={{ maxWidth: 900, marginBottom: "10px" }}>
                <CardMedia
                    sx={{ height: 140 }}
                    image={score.cardImg}
                    title={score.title}
                />

                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        <Link
                            className="Play-a"
                            href={score.url}
                            target="_blank"
                            rel="noreferrer"
                            color="inherit"
                            underline="none"
                        >
                            {score.author} - {score.title} [{score.difficulty}]
                            {score.mods.length > 0 ? " + " + score.mods.join(", ") : ""}
                        </Link>
                    </Typography>
                </CardContent>

                <Divider variant="middle" />

                <CardContent
                    sx={{
                        display: "flex",
                        flexGrow: "1",
                        justifyContent: "space-between",
                    }}
                >
                    <HitCount statistics={score.statistics} />
                    <Typography variant="body" color="text.primary">
                        {score.accuracy}%
                    </Typography>
                </CardContent>
                <Divider variant="middle" />
                <CardContent
                    sx={{
                        display: "flex",
                        flexGrow: "1",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="body" color="text.primary">
                        {score.combo}/{score.maxCombo}x
                    </Typography>
                    <Typography variant="body" color="text.primary">
                        {Math.round(score.pp)}pp
                    </Typography>
                </CardContent>
            </Card>
        </Tooltip>
    );
}

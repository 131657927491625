import "./App.css";
import { Switch, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import ScorePage from "./components/ScorePage";
import Skins from "./components/Skins";
import NotFound from "./components/NotFound";
import { CssBaseline } from "@mui/material";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#222222",
        },
        
    },
});

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <Navbar />

                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/plays" component={ScorePage} />
                    <Route exact path="/skins" component={Skins} />
                    <Route component={NotFound}/>
                </Switch>
            </ThemeProvider>
        </div>
    );
}

export default App;

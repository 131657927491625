import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";
import { CircularProgress, Fade, Alert, Grid, Typography } from "@mui/material";
import Play from "./Plays/Play";
import { functions } from "../firebase";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import "../styles/Plays.css";

const ScorePage = () => {
    const [plays, setPlays] = useState([]);
    const [noLoad, setNoLoad] = useState(false);
    // const [alerts, setAlerts] = useState();

    const [getPlays, loading, error] = useHttpsCallable(functions, "getPlays");
    useEffect(() => {
        getPlays({
            userId: 11625048,
        }).then((data) => {
        
            setPlays(data.data);
        })
        .catch((err) => { 
            console.log(err);
            setNoLoad(true);
        });
    }, []);

    if (loading) {
        return (
            <Container
                maxWidth="xl"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20vh",
                }}
            >
                <CircularProgress />
            </Container>
        );
    }

    if (noLoad || plays === "Error" || error) {
        console.log(error);
        return (
            <Alert
                onClose={() => {
                    setNoLoad(false);
                }}
                color="error"
            >
                Unable to load stats
            </Alert>
        );
    }
    console.log(plays)

    return (
        <Container>
            <Fade in={true} timeout={750}>
                <Grid container spacing={1}>
                    <Grid container item xs={12} justifyContent="center">
                        <Typography variant="h2" className="Plays-TopPlays" sx={{
                            marginY: "5vh",
                            textAlign: "center",
                        }}>Recent Scores</Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={3} justifyContent="center">
                        {plays.map((play) => {
                            return (
                                <Grid item xs={12} md={6} >
                                    <Play score={play}/>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Fade>
        </Container>
    );
};

export default ScorePage;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { IconButton, Menu, MenuItem } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";

const Navbar = (props) => {
    const links = ["Home", "Skins", "Plays"];
    const [anchorEl, setAnchorEl] = React.useState(null);
    return (
        <Box
            sx={{
                flexGrow: 1,
            }}
        >
            <AppBar position="static">
                <Toolbar
                    sx={{
                        justifyContent: { xs: "space-between", md: "center" },
                    }}
                >
                    <Box
                        sx={{
                            display: {
                                xs: "none",
                                md: "flex",
                            },
                        }}
                    >
                        {links.map((link) => (
                            <MenuItem
                                to={
                                    link === "Home"
                                        ? ""
                                        : `/${link.toLowerCase()}`
                                }
                                sx={{
                                    borderRadius: "5px"
                                }}
                                component={Link}
                                color="inherit"
                                key={link}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "500",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {link}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Box>
                    <Box sx={{ display: { xs: "block", md: "none" } }}>
                        <IconButton
                            color="inherit"
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            onClose={() => setAnchorEl(null)}
                        >
                            {links.map((link) => (
                                <MenuItem
                                    to={
                                        link === "Home"
                                            ? ""
                                            : `/${link.toLowerCase()}`
                                    }
                                    component={Link}
                                    color="inherit"
                                    onClick={() => setAnchorEl(null)}
                                    key={link}
                                    sx={{ padding: "10px 20px" }}
                                >
                                    {link}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Navbar;

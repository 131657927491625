const { initializeApp } = require("firebase/app");
const { getFunctions } = require("firebase/functions");

const firebaseConfig = {
    apiKey: "AIzaSyB28YdvjLn4AU8mGgrlf_3c1VMX-uZ0DZc",

    authDomain: "kawaosusite.firebaseapp.com",

    projectId: "kawaosusite",

    storageBucket: "kawaosusite.appspot.com",

    messagingSenderId: "3296213686",

    appId: "1:3296213686:web:879dd0d25077f7ba064417",
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
export {app, functions}

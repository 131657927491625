import React from "react";
import { Typography } from "@mui/material";

export default function HitCount({ statistics }) {
    const { count_100, count_50, count_miss } = statistics;
    return (
        <Typography variant="body" component="div">
            <Typography variant="body" color="green">
                {count_100}
            </Typography>
            <Typography variant="body" color="text.secondary">
                {" / "}
            </Typography>
            <Typography variant="body" color="blue">
                {count_50}
            </Typography>
            <Typography variant="body" color="text.secondary">
                {" / "}
            </Typography>
            <Typography variant="body" color="red">
                {count_miss}
            </Typography>
        </Typography>
    );
}

import React, { Component } from "react";
import { Grid, Typography } from "@mui/material";

export default class NotFound extends Component {
    render() {
        return (
            <Grid container spacing={1} mt="35vh" justifyContent="Center">
                <Typography variant="h3">Page Not Found!</Typography>
            </Grid>
        );
    }
}

import React, { Component } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";

export default class Skin extends Component {
    render() {
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{this.props.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Button style={{marginBottom: "5px"}}variant="outlined" href={this.props.dLink}>Download</Button>
                    <img
                        alt="Skin"
                        src={this.props.img}
                        style={{ maxWidth: "100%" }}
                    ></img>
                </AccordionDetails>
            </Accordion>
        );
    }
}

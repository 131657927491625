import React, { Component, useState, useEffect } from "react";
import { Container } from "@mui/system";
import { Alert, Collapse, Fade } from "@mui/material";
import { functions } from "../firebase";
import "../styles/home.css";
import osulogo from "../imgs/osulogo.webp";
import discord from "../imgs/transparent-discord.png";
import yt from "../imgs/White-YouTube-Logo-Transparent.png";
import { useHttpsCallable } from "react-firebase-hooks/functions";

const Home = (props) => {
    const [isHoveringStats, setIsHoveringStats] = useState(false);
    const [disc, setDisc] = useState(false);
    const [noLoad, setNoLoad] = useState(false);
    const [stats, setStats] = useState({
        pp: 0,
        rank: 0,
    });
    const [getOsuStats, loading, error] = useHttpsCallable(
        functions,
        "getStats"
    );
    useEffect(() => {
        getOsuStats({
            userId: 11625048,
        })
            .then((result) => {
                setStats({ ...result.data,  });
            })
            .catch((error) => {
                console.log(error);
                setNoLoad(true);
            });
    }, []);
    const handleHoverEvent = () => {
        if (isHoveringStats) {
            setIsHoveringStats(false);
        } else {
            setIsHoveringStats(true);
        }
    };
    return (
        <div>
            <div>
                <Collapse in={disc} unmountOnExit>
                    <Alert
                        onClose={() => {
                            setDisc(false);
                        }}
                    >
                        Discord Copied to Clipboard
                    </Alert>
                </Collapse>
            </div>

            {noLoad ? (
                <Alert
                    onClose={() => {
                        setNoLoad(false);
                    }}
                    color="error"
                >
                    Unable to load stats
                </Alert>
            ) : (
                ""
            )}
            <Container
                maxWidth="xl"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        marginTop: "11vh",
                        textAlign: "center",
                    }}
                >
                    <div>
                        <Fade in={!loading && !noLoad} timeout={750}>
                            <div className="Home-Stats-Visible">
                                <span>
                                    Rank: {stats.rank > 1000 ? ((Math.floor(stats.rank / 100) / 10) + "k") : stats.rank}
                                </span>
                                {" - "}
                                <span>{Math.round(stats.pp)}pp </span>
                            </div>
                        </Fade>
                    </div>
                    <div>
                        <img
                            className="Home-Pfp"
                            src="https://a.ppy.sh/11625048"
                            alt="profile"
                        ></img>
                        <h1 className="Home-Title">Kawambiit</h1>
                    </div>
                    <div
                        className="Home-Socials"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "30px",
                        }}
                    >
                        <a
                            href="https://osu.ppy.sh/users/11625048"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={osulogo}
                                alt="osu profile"
                                srcSet=""
                            ></img>
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UCHtUCne09FoCjnXwZpKu8Dw/videos"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={yt} alt="youtube channel" srcSet=""></img>
                        </a>

                        <img
                            src={discord}
                            alt="discord"
                            srcSet=""
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setDisc(true);
                                navigator.clipboard.writeText("Kawambiit#0049");
                            }}
                        ></img>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Home;

import React, { Component, useState } from "react";
import { Container } from "@mui/system";
import { Fade } from "@mui/material";
import Aristia from "../imgs/aristia.jpg";
import whitecat from "../imgs/whitecat.jpg";
import rafis from "../imgs/rafis.jpg";
import boop from "../imgs/boop.jpg";
import MonkoEdit from "../imgs/MonkoEdit.jpg";
import Skin from "./Skin";

const Skins = (props) => {
    const [skins, setSkins] = useState([
        {
            name: "Aristia -Edit-",
            img: Aristia,
            dLink: "https://1drv.ms/u/s!AkOhKmxXda_Gh6saHCJC3TRg8PMMPw?e=abMntu",
        },
        {
            name: "Whitecat 2.1",
            img: whitecat,
            dLink: "https://1drv.ms/u/s!AkOhKmxXda_Ghu8LTJgfamvDPTah0A?e=hCQ3Vr",
        },
        {
            name: "Rafis DT",
            img: rafis,
            dLink: "https://1drv.ms/u/s!AkOhKmxXda_Ghu8MvBVGWyz1SsKKoA?e=ajUGw5",
        },
        {
            name: "Boop",
            img: boop,
            dLink: "https://1drv.ms/u/s!AkOhKmxXda_GjK1YT5dx0h9G9j14eg?e=tWtVni",
        },
        {
            name: "MonkoEdit",
            img: MonkoEdit,
            dLink: "https://skins.osuck.net/index.php?newsid=2011",
        },
        {
            name: "Misto's Rafis Edit",
            img: rafis,
            dLink: "https://cdn.discordapp.com/attachments/957512558506545182/1059243795633418400/miztos_Rafis_HDDT_edit.osk",
        },
    ]);
    return (
        <Fade in={true} timeout={750}>
            <Container
                maxWidth="lg"
                sx={{
                    justifyContent: "center",
                    marginTop: "10vh",
                }}
            >
                {skins.map((skin) => (
                    <Skin name={skin.name} img={skin.img} dLink={skin.dLink} />
                ))}
            </Container>
        </Fade>
    );
};

export default Skins;
